import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModalThreeButtonsComponent} from '@modules/shared/modal/modal-three-buttons.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ApiService} from '@modules/shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-announcement-item',
  templateUrl: './announcement-item.component.html',
  styleUrls: ['./announcement-item.component.css']
})
export class AnnouncementItemComponent implements OnInit {

  @Input() announcement;
  @Input() mode;
  @Output() deleteEvent = new EventEmitter<string>();
  dateString = '';
  fromDateString;
  toDateString;
  endString;
  startString;
  constructor(private modalService: NgbModal, private apiService: ApiService, private toastr: ToastrService, private translocoService:
  TranslocoService) {
    translocoService.langChanges$.subscribe(lang => {
      this.translocoService.load(lang).subscribe(value => {
        this.fromDateString = this.translocoService.translate('common.from_date');
        this.toDateString = this.translocoService.translate('common.to_date');
        this.endString = this.translocoService.translate('common.end');
        this.startString = this.translocoService.translate('common.start');
      });
    });
  }

  ngOnInit(): void {
    this.fromDateString = this.translocoService.translate('common.from_date');
    this.toDateString = this.translocoService.translate('common.to_date');
    this.endString = this.translocoService.translate('common.end');
    this.startString = this.translocoService.translate('common.start');
    if (this.announcement.start_date && this.announcement.finish_date) {
      this.dateString = `${this.fromDateString} ${this.announcement.start_date} ${this.fromDateString} ${this.announcement.finish_date}`;
    } else if (this.announcement.start_date) {
      this.dateString = `${this.startString}: ${this.announcement.start_date}`;
    } else if (this.announcement.finish_date) {
      this.dateString = `${this.endString}: ${this.announcement.finish_date}`;
    }
  }

  onClickDelete() {
    const modal = this.modalService.open(ModalThreeButtonsComponent);
    modal.componentInstance.title = this.translocoService.translate('common.delete_announcement');
    modal.componentInstance.mainText = this.translocoService.translate('common.confirm_delete_announcement');
    modal.componentInstance.dangerText = this.translocoService.translate('common.danger_action');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.no');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.yes_delete');
    modal.result.then((data) => {
      if (data === 'buttonRight') {
        this.apiService.deleteAnnouncement(this.announcement.id).subscribe(response => {
          if (response.success) {
            this.toastr.success('', this.translocoService.translate('common.announcement_deleted'));
            this.deleteEvent.emit('');
          }
        }, error => {
          this.toastr.error('', this.translocoService.translate('common.internal_error'));
        });
      }
    });
  }
}
