import {Component, Input, OnInit, ViewChild} from '@angular/core';
import { Player } from '@vime/angular';

@Component({
  selector: 'app-view-video',
  templateUrl: './view-video.component.html',
  styleUrls: ['./view-video.component.css']
})
export class ViewVideoComponent implements OnInit {
  @Input() resource;
  @ViewChild('player') player!: Player;
  youtubeId = '';
  constructor() { }

  ngOnInit(): void {
    if (this.resource.source_type === 'youtube') {
      const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
      const match = this.resource.url.match(regExp);
      if (match && match[2].length === 11) {
        this.youtubeId = match[2];
      } else {
        // error
      }
    }
  }
  onPlayerReady() {
    if (this.resource.source_type === 'file') {
      document.getElementsByTagName('video').item(0).setAttribute('controlsList', 'nodownload');
    }
  }

}
