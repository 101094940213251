import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="modal-body">
      <p><strong>{{mainText}}</strong></p>
      <p>{{subText}}
        <span class="text-danger">{{dangerText}}</span>
      </p>
    </div>


  </div>
  <div class="modal-footer">
    <button *ngIf="buttonLeft.length > 0"type="button" class="btn btn-outline-secondary" (click)="modal.close('buttonLeft')">{{buttonLeft}}</button>
    <button *ngIf="buttonCenter.length > 0" type="button" class="btn btn-danger" (click)="modal.close('buttonCenter')">
      {{buttonCenter}}</button>
    <button *ngIf="buttonRight.length > 0"type="button" class="btn btn-danger" (click)="modal.close('buttonRight')">{{buttonRight}}</button>
  </div>
  `
})

export class ModalThreeButtonsComponent {
  type: string;
  mainText = '';
  subText = '';
  dangerText = '';
  title = '';
  buttonRight = '';
  buttonLeft = '';
  buttonCenter = '';

  constructor(public modal: NgbActiveModal) { }
}
