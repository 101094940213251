import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import {NgbDateAdapter, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter, CustomDateParserFormatter} from '@modules/shared/providers/date-providers';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ]
})

export class AppComponent implements OnInit {
  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = null;
  modalOpen: Boolean = false;


  constructor(private titleService: Title,
    private router: Router,
    ) {}


  ngOnInit() {
    // ---------------------------  Browser Title  ----------------------------------
    const appTitle = this.titleService.getTitle();
    const parentContext = this;
      // Listens for changes in local storage IN OTHER OPEN PAGES (storageEvent)
      // if 'isLoggedin' has changed to false, redirect to login page
      window.addEventListener('storage', function(storageEvent) {
        if (storageEvent.key === 'isLoggedin' && storageEvent.newValue === 'false') {
          parentContext.router.navigate(['/login']);
        }
      });
  }
}
