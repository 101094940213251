import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, Validator, ValidatorFn} from '@angular/forms';

@Directive({
  selector: '[appEqualValidator]',
  providers: [{provide: NG_VALIDATORS, useExisting: EqualValidatorDirective, multi: true}]
})
export class EqualValidatorDirective implements Validator {

  @Input('appEqualValidator') equalName: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    return this.equalName ? equalValueValidator(this.equalName)(control)
      : null;
  }
}

export function equalValueValidator(nameRe: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const forbidden = control.value !== nameRe;
    return forbidden ? {'equalName': {value: control.value}} : null;
  };
}

