import { HttpClient } from '@angular/common/http';
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule
} from '@ngneat/transloco';
import { Injectable, NgModule } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) {}

  getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}


export function getBrowserLangCode() {
  if (localStorage.getItem('ss_user_lang') && ['spa','eng', 'ara'].includes(localStorage.getItem('ss_user_lang'))) {
    return localStorage.getItem('ss_user_lang');
  } else {
    if (navigator.language.startsWith('ar')) {
      return 'ara'
    } else if (navigator.language.startsWith('en')) {
      return 'eng'
    } else {
      return 'spa'
    }
  }
}

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: ['eng', 'spa', 'ara'],
        defaultLang: getBrowserLangCode(),
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
      })
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
  ]
})
export class TranslocoRootModule {}
