import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import { onLoggedout } from '@modules/shared/shared.component';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {ApiService} from '@modules/shared/services/api.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private apiService: ApiService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    if (localStorage.getItem('isLoggedin') === 'true' && localStorage.getItem('token')) {
      return this.apiService.checkToken().pipe(
        map(e => {
          return true;
        }),
        catchError((err) => {
          onLoggedout();
          this.router.navigate(['/login']);
          return of(false);
        })
      );
    } else {
      onLoggedout();
      this.router.navigate(['/login']);
      return of(false);
    }

  }

}
