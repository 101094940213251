import {Component, Input, OnInit} from '@angular/core';
import {Fill, Stroke, Style, Icon} from 'ol/style';
import {Feature} from 'ol';
import Point from 'ol/geom/Point';
import * as olProj from 'ol/proj';
import {LineString} from 'ol/geom';
import VectorSource from 'ol/source/Vector';
import {Vector as VectorLayer} from 'ol/layer';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import {Stamen} from 'ol/source';
import { environment } from '@env/environment';

@Component({
  selector: 'app-mini-map',
  templateUrl: './mini-map.component.html',
  styleUrls: ['./mini-map.component.css']
})
export class MiniMapComponent implements OnInit {
  map: Map;
  @Input() coordinates;
  constructor() { }

  ngOnInit(): void {
    this.buildMap();
  }

  buildMap() {
    function resouscePointStyleFunction(feature, resolution) {
      let color = '';
      let src = '';
      if (feature.get('multimediaType') === 'video') {
        color = '#4372c9';
        src = 'assets/images/svg-icons/video.svg';
      } else if (feature.get('multimediaType') === 'audio') {
        color = '#c00d0d';
        src = 'assets/images/svg-icons/audio.svg';
      } else if (feature.get('multimediaType') === 'document') {
        color = '#7ce38d';
        src = 'assets/images/svg-icons/document.svg';
      } else {
        color = '#ffc956';
        src = 'assets/images/svg-icons/img.svg';
      }
      return new Style({
        image: new Icon({
          src: src,
        })
      });
    }

    const borderItems = [];

    function borderStyleFunction(feature, resolution) {
      return new Style({
        stroke: new Stroke({
          color: '#96968d',
          width: 3,
          lineCap: 'square',
          lineDash: [4, 7]
        })
      });
    }
    const c = this.coordinates.split(',');
    const cn = [+(c[1]).trim(), +c[0]];
    const iconPoint = new Feature({
      geometry: new Point(olProj.fromLonLat(cn)),
    });

    const vectorSource = new VectorSource({
      features: [iconPoint],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: resouscePointStyleFunction
    });

    const borderLine = new Feature({
      geometry: new LineString([olProj.fromLonLat([-13.172624970064488, 27.667259406704968]),
        olProj.fromLonLat([-8.667611389974295, 27.667259406704968])]),
      name: 'Sahara',
      type: 'fixed'
    });


    borderItems.push(borderLine);

    const borderSource = new VectorSource({
      features: [...borderItems],
    });

    const borderLayer = new VectorLayer({
      source: borderSource,
      style: borderStyleFunction
    });
    let source;
    if (environment.client.mapType === 'terrain'){
      source = new Stamen({
        layer: 'terrain',
      });
    } else {
      source = new OSM({
      })
    }
    this.map = new Map({
      target: 'hotel_map',
      layers: [
        new TileLayer({
          source: source,
        }), borderLayer, vectorLayer
      ],
      view: new View({
        center: olProj.fromLonLat(cn),
        zoom: 15
      })
    });
  }

}
