import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-common-table',
  templateUrl: './common-table.component.html',
  styleUrls: ['./common-table.component.css']
})
export class CommonTableComponent implements OnInit {
  @ViewChild('editTmpl', {static: false}) editTmpl: TemplateRef<any>;
  @ViewChild('hdrTpl', {static: false}) hdrTpl: TemplateRef<any>;
  @ViewChild('table', {static: false}) ngxTable;
  @Input() showAddButton = false;
  @Input() showRefreshButton = false;
  @Output() addButtonEmitter = new EventEmitter<string>();
  @Output() deleteButtonEmitter = new EventEmitter<string>();
  rows;
  columns;
  constructor(private apiService: ApiService, private toastr: ToastrService, private modalService: NgbModal,
    private translocoService: TranslocoService) { }

  ngOnInit(): void {
  }
  addMethod() {
    this.addButtonEmitter.emit();
  }
  deleteMethod(rowId) {
    this.deleteButtonEmitter.emit(rowId);
  }
  refreshMethod() {}
  changeColumns(newColumns) {
    this.ngxTable.console = this.columns;
  }
  setColumns(columns, buttons: [string]) {
    this.columns = columns;
    for (const button of buttons) {
      if (button === 'delete') {
        this.columns.push({
          prop: 'delete',
          name: '',
          width: 50,
          cellTemplate: this.editTmpl
        });
      
      }
    }
  }
}
