import {Component, OnInit} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import View from 'ol/View';
import * as olProj from 'ol/proj';
import {Feature, } from 'ol';
import {Fill, Stroke, Style, Circle} from 'ol/style';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import {Vector as VectorLayer} from 'ol/layer';
import {ApiService} from '@modules/shared/services/api.service';
import {LineString} from 'ol/geom';
import {Stamen} from 'ol/source';
import {TranslocoService} from '@ngneat/transloco';
import { environment } from '@env/environment';

@Component({
  selector: 'app-modal-map',
  template: `
    <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{'common.map' | transloco}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      <p><strong>{{showCommonPlaces ? 'common.map_click' : 'common.map_click_to' | transloco}}</strong></p>

    <div *ngIf="showCommonPlaces" class="row">
      <div class="col col-6 col-md-12">
        <div class="form-group">
          <label for="common">{{'common.frequent_locations' | transloco}}</label>

          <select (change)="onChangeCommonPlace()" [(ngModel)]="selectedCommonPlace" class="form-control" id="common"
                  name="common">
            <option *ngFor="let c of commonPlaces" [value]="c.coordinates">{{c.label}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col col-11 mx-auto">
        <div style="height: 300px;" id="hotel_map"></div>
      </div>
    </div>
  </div>
  <div *ngIf="!showCommonPlaces" class="row">
    <div style="margin-left: 2rem;" class="col col-10">
      <div class="form-group">
        <label for="common">{{'common.name' | transloco}}</label>

        <input type="text" class="form-control" id="common-place-label" name="common-place-label" [(ngModel)]="commonPlaceLabel">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('cancel')">{{'common.close' | transloco}}</button>
    <button [disabled]="!currentPosition.coordinates" type="button" class="btn btn-danger" (click)="onClickSave()">{{'common.save' | transloco}}</button>
  </div>
  `
})

export class ModalMapComponent implements OnInit {
  type: string;
  mainText = '';
  title = '';
  buttonRight = '';
  buttonLeft = '';
  comments = '';
  map: Map;
  showCommonPlaces = true;
  currentPointLayer;
  currentCoordinates;
  currentPosition = {
    coordinates: undefined,
    label: ''
  };
  commonPlaces = [];
  selectedCommonPlace: '';
  selectedLabel = '';
  commonPlaceLabel = '';
  constructor(private apiService: ApiService,
              public modal: NgbActiveModal,
              private translocoService: TranslocoService) { }

  ngOnInit(): void {
    this.buildMap();
    this.getCommonPlaces();
  }
  getCommonPlaces() {
    this.apiService.getCommonPlaces().subscribe(response => {
      this.commonPlaces = response.data;
    });
  }
  onChangeCommonPlace() {
    this.selectedLabel = this.commonPlaces.find(co => co.coordinates === this.selectedCommonPlace).label;
    const c = this.selectedCommonPlace.split(',');
    const cn = [+(c[1]).trim(), +c[0]];
    this.createPoint(cn, 'common');
    this.changeMapView(cn);
  }
  buildMap() {

    const fixedPoints = [];
    const borderItems = [];



    function borderStyleFunction(feature, resolution) {
      return new Style({
        stroke: new Stroke({
          color: '#96968d',
          width: 3,
          lineCap: 'square',
          lineDash: [4, 7]
        })
      });
    }
    const saharaFixedPoint =  new Feature({
      geometry: new Point(olProj.fromLonLat([-13.2154, 24.9215])),
      name: 'Sahara',
      type: 'fixed'
    });

    const borderLine = new Feature({
      geometry: new LineString([olProj.fromLonLat([-13.172624970064488, 27.667259406704968]),
        olProj.fromLonLat([-8.667611389974295, 27.667259406704968])]),
      name: 'Sahara',
      type: 'fixed'
    });


    fixedPoints.push(saharaFixedPoint);
    borderItems.push(borderLine);

    const borderSource = new VectorSource({
      features: [...borderItems],
    });

    const borderLayer = new VectorLayer({
      source: borderSource,
      style: borderStyleFunction
    });
    let source;
    if (environment.client.mapType === 'terrain'){
      source = new Stamen({
        layer: 'terrain',
      });
    } else {
      source = new OSM({
      })
    }
    this.map = new Map({
      target: 'hotel_map',
      layers: [
        new TileLayer({
          source: source
        }), borderLayer
      ],
      view: new View({
        center: olProj.fromLonLat(environment.client.mapCoordinates),
        zoom: environment.client.mapZoom
      })
    });
    this.map.on('singleclick',  (evt: any) => {
      this.onMapCLicked(evt.coordinate);
    });
  }

  onMapCLicked(coordinates) {
    this.selectedCommonPlace = '';
    this.createPoint(coordinates, 'click');
  }
  createPoint(coordinates, mode) {
    let iconPoint;
    if (mode === 'click') {
      iconPoint = new Feature({
        geometry: new Point(coordinates),
      });
    } else {
      iconPoint = new Feature({
        geometry: new Point(olProj.fromLonLat(coordinates)),
      });
    }
    function myVectorLayerStyleFunction(feature, resolution) {
      // Define los estilos para el círculo, el relleno y el borde
      const circle = new Circle({
        radius: 6,
        fill: new Fill({
          color: 'rgba(255, 255, 255)'
        }),
        stroke: new Stroke({
          color: 'rgba(0, 0, 0)',
          width: 5
        })
      });
    
      // Devuelve un objeto de estilo que incluye el círculo
      return new Style({
        image: circle
      });
    }
    const vectorSource = new VectorSource({
      features: [iconPoint],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
      style: myVectorLayerStyleFunction
    });
    if (this.currentPointLayer) {
      this.map.removeLayer(this.currentPointLayer);
    }
    this.map.addLayer(vectorLayer);
    this.currentPointLayer = vectorLayer;
    this.currentPosition.coordinates = mode === 'click' ? olProj.toLonLat(coordinates) : coordinates;
    this.currentPosition.label = mode === 'click' ? '' : this.selectedLabel;
  }
  onClickSave() {
    if (this.showCommonPlaces) {
      this.modal.close(this.currentPosition)
    } else {
      this.modal.close({'label': this.commonPlaceLabel, 'coordinates': this.currentPosition.coordinates})
    }
  }
  changeMapView(commonPlace) {
    this.map.setView(
      new View({
        center: olProj.fromLonLat(commonPlace),
        zoom: environment.client.mapZoom
      })
    );
  }
}
