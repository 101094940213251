import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMin][formControlName],[customMin][formControl],[customMin][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMinDirective, multi: true}]
})
export class CustomMinDirective implements Validator {
  @Input()
  customMin: number;
  formValue: number;
  
  validate(c: FormControl): {[key: string]: any} {
    if (c.value == null) {
        return null
    } else if (isNaN(c.value)) {
        return {"isNan": true}
    } else {
        this.formValue = Number(c.value)
    }
      return ( this.formValue < this.customMin)? {"customMin": true} : null;
  }
} 
