<div id="container">
  <vm-player (vmReady)="onPlayerReady()" #player controls="true">
    <vm-audio crossorigin="anonymous">
      <source data-src="{{resource.url}}" type="audio/mp3" />
    </vm-audio>
  </vm-player>
</div>

<style>
  :host {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #container {
    width: 100%;
    max-width: 960px;
  }
</style>
