import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: () => import('@core/core.module').then(m => m.CoreModule)},
  { path: 'register', loadChildren: () => import('@modules/register/register.module').then(m => m.RegisterModule),
    data: {title: 'Registro'}},
  { path: 'reset', loadChildren: () => import('@modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    data: {title: 'Restaurar'}},
  { path: 'login', loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule), data: {title: 'User Login'}},
  { path: 'not-found', loadChildren: () => import('@core/_modules/not-found/not-found.module').then(m => m.NotFoundModule),
    data: {title: 'Page Not Found'}},
  { path: '**', redirectTo: 'not-found', data: {title: 'Not Found'} }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }