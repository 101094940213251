import {DatePipe} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from '@app/app-routing.module';
import { AppComponent } from '@app/app.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {TokenInterceptor} from '@core/_services/token-interceptor';
import {AuthGuard} from '@modules/shared/guard';
import {RoleGuard} from '@modules/shared/guard/role.guard';
import {SharedModule} from '@modules/shared/shared.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { ToastrModule } from 'ngx-toastr';
import {FormsModule} from '@angular/forms';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { TranslocoRootModule } from './transloco-root.module';

@NgModule({
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    NgIdleKeepaliveModule.forRoot(),
    SharedModule,
    ToastrModule.forRoot(),
    FormsModule,
    NgxDatatableModule,
    TranslocoRootModule
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [NgbActiveModal, Title, DatePipe, { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    AuthGuard, RoleGuard],
  entryComponents: []
})
export class AppModule { }
