export const environment = {
  production: false,
  translate: {
    languages: ['es'],
    fileExtension: '.json',
  },
  api: {
    baseUrl: `https://${window.location.hostname}/api`,
    logosUrl: `https://${window.location.hostname}/resources/soundscapes/config/`,
    withCredentials: false
  },
  client: {
    id: 2,
    main_name: 'SaharaSoundscapes',
    mapCoordinates: [-9.8361, 28.9291],
    mapZoom: 5,
    mapType: 'open'
  }
};
