


<div class="modal-header">
  <h4 class="modal-title" id="modal-title">¿Quiénes somos?</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <div class="row">
    <div class="col col-11" style="margin: 1rem;">

      <p><strong>NUESTRA MEMORIA es parte de la tuya</strong>.</p>
      <p>La aplicación  SAHARA SOUNDSCAPES es producto de una tesis interactiva, que PONE en el CENTRO a la comunidad saharaui, sus luchas y saberes en-femenino desde el Art-ivismo y la Memoria Colectiva, intentando ser un instrumento que contribuya a CO-CONSTRUIR nuestra historia (s) desde aquellas otras voces silenciadas, que resuenan después de 47 años, y continúan gritando desde el exilio, la diáspora y la ocupación.</p>
      <p>Sahara Soundscapes se origina desde la Universidad de Granada: Instituto de Migraciones y Departamento de Antropología Social, que de la mano de la Escuela E.T.S de Ingenierías Informática y de Telecomunicación, y gracias a las interacciones producidas con esta a través del programa Carmenta de <a href="https://medialab.ugr.es/2020/01/07/1a-jornada-de-investigacion-en-ciencias-sociales-y-humanidades-digitales-de-la-universidad-de-granada/">Medialab UGR</a>;  comienza a crear encuentros inter-saberes durante más de cinco años, dando lugar hoy día, y a pesar de no haber podido tener ni conseguir ninguna ayuda económica de ninguna institución para este fin, dan lugar a esta herramienta compartida, que se pone al servicio de la comunidad para crear colectivamente.</p>
      <p>Conscientes de la importancia de la tecnología y la inteligencia artificial como herramientas idóneas para la difusión actual; la presente tesis doctoral se transmuta en una iniciativa electrónica que pretende hace patente el mundo sonoro saharaui; recogiendo informaciones acerca del entorno que rodea a este: Paisajes sonoros, fotografías, testimonios orales, canciones, documentos....etc.  </p>
      <p><em>¿Te gustaría participar? ¿Cómo puedes hacerlo?</em></p>
      <p>Muy fácil, simplemente tienes que REGISTRARTE con un nombre de usuaro@ y una <em>password</em> para garantizar de esta manera la integridad de tus datos. Solamente tú decidirás si la información que aportas podrá ser publicada descargada o no, bajo qué permiso (<em>Ver términos de uso y privacidad</em>).</p>
      <p><em>¿Por qué colaborar?</em></p>
      <p>Ayudarás a crecer el estudio de una parte de la historia tan importante como lo fue la Historia del Sáhara Occidental, antigua provincia española número 53. ¿Por qué? Porque el PUEBLO SAHARAUI No Está Sólo, y porque sus luchas también son las nuestras.</p>
      <p>Bienvenid@ a SaharaSoundscapes...</p>
    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-primary">Cerrar</button>
    </div>
  </div>
</div>
