import {Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '@modules/shared/services/api.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {mobileWidth, roles} from '@modules/shared/shared.component';
import {ModalThreeButtonsComponent} from '@modules/shared/modal/modal-three-buttons.component';
import {TranslocoService} from '@ngneat/transloco';

@Component({
  selector: 'app-relevant-links-list',
  templateUrl: './relevant-links-list.component.html',
  styleUrls: ['./relevant-links-list.component.css']
})
export class RelevantLinksListComponent implements OnInit {
  @ViewChild('editTmpl', {static: true}) editTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) titleTmpl: TemplateRef<any>;

  @ViewChild('table', {static: true}) ngxTable;
  rows;
  columns;
  public getScreenWidth: any;
  public getScreenHeight: any;
  @Input() relevantLinks = [];
  @Input() mode;

  constructor(private apiService: ApiService,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private translocoService: TranslocoService,
              ) {}

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.mode === 'moderate') {
      this.apiService.getMyRelevantLinks(this.mode).subscribe(response => {
        if (response.success) {
          this.relevantLinks = response.data.moderate;
          this.generateTable(this.relevantLinks);
        }
      });
    } else {
      this.getRelevantLinks();
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.generateTable(this.relevantLinks);
  }
  getRelevantLinks() {
    if (this.mode === 'board') {
      this.apiService.getRelevantLinks().subscribe(response => {
        if (response.success) {
          this.relevantLinks=  response.data;
          this.generateTable(this.relevantLinks);
        }
      });
    }
  }
  
  generateTable(data) {
    this.rows = data;
    this.columns = [

      {
        cellTemplate: this.titleTmpl,
        cellClass: 'resource-table-cell',
        name: '',
        sortable: false,
      },
    ];
  }
  getRowClass(row) {
    return 'resource-table-row';
  }
  onClickDelete(relevantLinkId) {
    const modal = this.modalService.open(ModalThreeButtonsComponent);
    modal.componentInstance.title = this.translocoService.translate('common.delete_link');
    modal.componentInstance.mainText = this.translocoService.translate('common.confirm_delete_link');
    modal.componentInstance.dangerText = this.translocoService.translate('common.danger_action');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.no');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.yes_delete');
    modal.result.then((data) => {
      if (data === 'buttonRight') {
        this.apiService.deleteRelevantLink(relevantLinkId).subscribe(response => {
          if (response.success) {
            this.toastr.success('', this.translocoService.translate('common.relevant_link_deleted'));
            this.getRelevantLinks();
          }
        }, error => {
          this.toastr.error('', this.translocoService.translate('common.internal_error'));
        });
      }
    });
  }
  showManageButtons() {
    if (this.mode === 'my_contributions') {
      return true;
    }
    if ((+localStorage.getItem('id_role') === roles.ADMINISTRADOR || +localStorage.getItem('id_role') === roles.EXPERTO) && this.mode !== 'moderate') {
      return true;
    }
    return false
  }
}
