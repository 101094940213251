import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '@modules/shared/services/api.service';
import {dateFromEngToSpaSlashes, mobileWidth, roles} from '@modules/shared/shared.component';
import {ToastrService} from 'ngx-toastr';
import {TranslocoService} from '@ngneat/transloco';
import { ModalThreeButtonsComponent } from '../../modal/modal-three-buttons.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-board-announcements-list',
  templateUrl: './board-announcements-list.component.html',
  styleUrls: ['./board-announcements-list.component.css']
})
export class BoardAnnouncementsListComponent implements OnInit, OnChanges {
  @Input() announcements;
  @Input() mode;
  @ViewChild('editTmpl', {static: true}) editTmpl: TemplateRef<any>;
  @ViewChild('imgTmpl', {static: true}) imgTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) titleTmpl: TemplateRef<any>;
  @ViewChild('infoTmpl', {static: true}) infoTmpl: TemplateRef<any>;
  @ViewChild('table', {static: true}) ngxTable;
  @Output() refreshData = new EventEmitter<string>();

  rows;
  columns;
  public getScreenWidth: any;
  public getScreenHeight: any;
  constructor(private apiService: ApiService,
              private translocoService: TranslocoService,
              private toastr: ToastrService,
              private modalService: NgbModal) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.generateTable(this.announcements);
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    this.getData();
  }
  getData() {
    if (this.mode === 'board') {
      this.apiService.getBoard().subscribe(response => {
        if (response.success) {
          this.announcements = response.data;
          this.generateTable(this.announcements);
        }
      });
    } else if (this.mode === 'moderate') {
      this.apiService.getMyAnnouncements(this.mode).subscribe(response => {
        if (response.success) {
          this.announcements = response.data.moderate;
          this.generateTable(this.announcements);
        }
      });
    }
  }
  generateTable(data) {
    this.rows = data;
    this.columns = [

      {
        cellTemplate: this.titleTmpl,
        cellClass: 'resource-table-cell',
        name: '',
        sortable: false,
        

      },
    ];
  }
  getRowClass(row) {
    return 'resource-table-row';
  }
  onClickDelete(announcementId) {
    const modal = this.modalService.open(ModalThreeButtonsComponent);
    modal.componentInstance.title = this.translocoService.translate('common.delete_announcement');
    modal.componentInstance.mainText = this.translocoService.translate('common.confirm_delete_announcement');
    modal.componentInstance.dangerText = this.translocoService.translate('common.danger_action');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.no');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.yes_delete');
    modal.result.then((data) => {
      if (data === 'buttonRight') {
        this.apiService.deleteAnnouncement(announcementId).subscribe(response => {
          if (response.success) {
            this.toastr.success('', this.translocoService.translate('common.announcement_deleted'));
            this.refreshData.emit('refresh');
            this.getData();
          }
        }, error => {
          this.toastr.error('', this.translocoService.translate('common.internal_error'));
        });
      }
    });
  }
  showManageButtons() {
    if (this.mode === 'my_contributions') {
      return true;
    }
    if ((+localStorage.getItem('id_role') === roles.ADMINISTRADOR || +localStorage.getItem('id_role') === roles.EXPERTO) && this.mode !== 'moderate') {
      return true;
    }
    return false
  }
}
