import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import { Player } from '@vime/angular';
@Component({
  selector: 'app-view-audio',
  templateUrl: './view-audio.component.html',
  styleUrls: ['./view-audio.component.css']
})
export class ViewAudioComponent implements OnInit {
  @Input() resource;
  @ViewChild('player') player!: Player;

  constructor() { }

  ngOnInit(): void {
  }
  onPlaybackReady() {
    // ...
  }
  onPlayerReady() {
    document.getElementsByTagName('audio').item(0).setAttribute('controlsList', 'nodownload');
  }
}
