<ng-container *transloco="let t; read: 'common'">

<div style="border-style: solid; min-height: 8rem;">
  <div style="margin-left: 1rem; margin-top: 1rem;" class="row">
    <div class="col-12 col-lg-7">
      <h4 style="word-wrap: break-word" class="announcement-title">{{announcement.title}}</h4>
    </div>
    <div class="col-12 col-lg-3 offset-lg-2">
      <span class="announcement-username">{{t('published_by')}}: {{announcement.username}}</span>
      <br>
      <span class="announcement-start-date"> {{dateString}} </span>
    </div>
  </div>

  <div style="margin-top: 2rem; margin-left: 1rem" class="row">
    <div class="col col-11">
      <p style="word-wrap: break-word" class="announcement-body">{{announcement.body}}</p>
    </div>
  </div>


  <div *ngIf="mode === 'board' && announcement.url" style="margin-top: 2rem; margin-left: 1rem; margin-bottom: 1rem" class="row">
    <div class="col col-11 mx-auto">
      <app-view-photo [resource]="announcement"></app-view-photo>
    </div>
  </div>

  <div *ngIf="this.mode === 'my-announcements'" style="margin-top: 2rem; margin-bottom: 1rem; margin-left: 0.5rem" class="row">
    <div class="col-12 col-lg-4 offset-lg-9">
      <button type="button" class="btn btn-primary" [routerLink]="['/anuncios/editar/', announcement.id]">{{t('edit')}}</button>
      <button style="margin-left: 0.5rem;" type="button" class="btn btn-warning" (click)="onClickDelete()">{{t('delete')}}</button>
    </div>
  </div>
  <div *ngIf="this.mode === 'moderate'" style="margin-top: 2rem; margin-bottom: 1rem; margin-left: 0.5rem" class="row">
    <div class="col-12 col-lg-4 offset-lg-10">
      <button type="button" class="btn btn-primary" [routerLink]="['/moderar/anuncio/', announcement.id]">{{t('moderate')}}</button>
    </div>
  </div>
</div>

</ng-container>
