
<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Política de datos</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">


  <div class="row">
    <div class="col col-11" style="margin: 1rem;">

      <p><strong>Protección de datos de carácter personal según la LOPD</strong></p>
      <p>Sahara Soundscapes, en aplicación de la normativa vigente en materia de protección de datos de carácter personal, informa que los datos personales que se recogen a través de los formularios del Sitio web: www.saharasoundscapes.org, se incluyen en los ficheros automatizados específicos de usuarios de los servicios de Sahara Soundscapes.</p>
      <p>La recogida y tratamiento automatizado de los datos de carácter personal tiene como finalidad el mantenimiento de la relación de ofrecimiento de servicios y el desempeño de tareas de información, formación, asesoramiento y otras actividades propias de Sahara Soundscapes.</p>
      <p>Estos datos únicamente serán cedidos a aquellas entidades que sean necesarias con el único objetivo de dar cumplimiento a la finalidad anteriormente expuesta.</p>
      <p>Sahara Soundscapes adopta las medidas necesarias para garantizar la seguridad, integridad y confidencialidad de los datos conforme a lo dispuesto en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.</p>
      <p>El usuario podrá en cualquier momento ejercitar los derechos de acceso, oposición, rectificación y cancelación reconocidos en el citado Reglamento (UE). El ejercicio de estos derechos puede realizarlo el propio usuario a través de email a admin@saharasoundscapes.org</p>
      <p>El usuario manifiesta que todos los datos facilitados por él son ciertos y correctos, y se compromete a mantenerlos actualizados, comunicando los cambios a Sahara Soundscapes, ya sea a través de las herramientas que proporciona la aplicación web alojada en www.saharasoundscapes.org, o bien enviando un correo a admin@saharasoundscapes.org</p>
      <p><strong>Finalidad del tratamiento de los datos personales:</strong></p>
      <p><strong>¿Con qué finalidad trataremos tus datos personales?</strong></p>
      <p>EnSahara Soundscapes, trataremos tus datos personales recabados a través del Sitio Web: www.saharasoundscapes.org, con las siguientes finalidades:</p>
      <ol>
        <li><p>Para mantener la relación de prestación de servicios, así como la gestión, administración, información, prestación y mejora del servicio.</p>
        </li>
        <li><p>Envío de información solicitada a través de los formularios dispuestos en www.saharasoundscapes.org</p>
        </li>
        <li><p>Remitir boletines (newsletters), así como comunicaciones de www.saharasoundscapes.org y del sector.</p>
        </li>
      </ol>
      <p>Te recordamos que puedes oponerte al envío de comunicaciones comerciales por cualquier vía y en cualquier momento, remitiendo un correo electrónico a la dirección indicada anteriormente.</p>
      <p>Los campos de dichos registros son de cumplimentación obligatoria, siendo imposible realizar las finalidades expresadas si no se aportan esos datos.</p>
      <p><strong>¿Por cuánto tiempo se conservan los datos personales recabados?</strong></p>
      <p>Los datos personales proporcionados se conservarán mientras se mantenga la relación de prestación de servicios o no solicites su supresión y durante el plazo por el cuál pudieran derivarse responsabilidades legales por los servicios prestados.</p>
      <p><strong>Legitimación:</strong></p>
      <p>El tratamiento de tus datos se realiza con las siguientes bases jurídicas que legitiman el mismo:</p>
      <ol>
        <li><p>La solicitud de información y/o la prestación de los servicios de Sahara Soundscapes, cuyos términos y condiciones se pondrán a tu disposición en todo caso, de forma previa a una eventual contratación.</p>
        </li>
        <li><p>El consentimiento libre, específico, informado e inequívoco, en tanto que te informamos poniendo a tu disposición la presente política de privacidad, que tras la lectura de la misma, en caso de estar conforme, puedes aceptar mediante una declaración o una clara acción afirmativa, como el marcado de una casilla dispuesta al efecto.</p>
        </li>
      </ol>
      <p>En caso de que no nos facilites tus datos o lo hagas de forma errónea o incompleta, no podremos atender tu solicitud, resultando del todo imposible proporcionarte la información solicitada o llevar a cabo la contratación de los servicios.</p>
      <p><strong>Destinatarios:</strong></p>
      <p>Los datos no se comunicarán a ningún tercero ajeno a Sahara Soundscapes, salvo obligación legal.</p>
      <p><strong>Datos recopilados por usuarios de los servicios</strong></p>
      <p>En los casos en que el usuario incluya ficheros con datos de carácter personal en los servidores de alojamiento compartido, Sahara Soundscapes no se hace responsable del incumplimiento por parte del usuario del RGPD.</p>
      <p><strong>Retención de datos en conformidad a la LSSI</strong></p>
      <p>Sahara Soundscapes informa de que, como prestador de servicio de alojamiento de datos y en virtud de lo establecido en la Ley 34/2002 de 11 de julio de Servicios de la Sociedad de la Información y de Comercio Electrónico (LSSI), retiene por un periodo máximo de 12 meses la información imprescindible para identificar el origen de los datos alojados y el momento en que se inició la prestación del servicio. La retención de estos datos no afecta al secreto de las comunicaciones y sólo podrán ser utilizados en el marco de una investigación criminal o para la salvaguardia de la seguridad pública, poniéndose a disposición de los jueces y/o tribunales o del Ministerio que así los requiera.</p>
      <p>La comunicación de datos a las Fuerzas y Cuerpos del Estado se hará en virtud a lo dispuesto en la normativa sobre protección de datos personales.</p>
      <p><strong>Propiedad intelectual del software</strong></p>
      <p>El usuario debe respetar los programas de terceros puestos a su disposición por Sahara Soundscapes, aun siendo gratuitos y/o de disposición pública.</p>
      <p>Sahara Soundscapes dispone de los derechos de explotación y propiedad intelectual necesarios del software.</p>
      <p>El usuario no adquiere derecho alguno o licencia por el servicio contratado, sobre el software necesario para la prestación del servicio, ni tampoco sobre la información técnica de seguimiento del servicio, excepción hecha de los derechos y licencias necesarios para el cumplimiento de los servicios contratados y únicamente durante la duración de los mismos.</p>
      <p>Para toda actuación que exceda del cumplimiento del contrato, el usuario necesitará autorización por escrito por parte de Sahara Soundscapes, quedando prohibido al usuario acceder, modificar, visualizar la configuración, estructura y ficheros de los servidores propiedad deSahara Soundscapes, asumiendo la responsabilidad civil y penal derivada de cualquier incidencia que se pudiera producir en los servidores y sistemas de seguridad como consecuencia directa de una actuación negligente o maliciosa por su parte.</p>
      <p><strong>Propiedad intelectual de los contenidos alojados</strong></p>
      <p>Se prohíbe el uso contrario a la legislación sobre propiedad intelectual de los servicios prestados por Sahara Soundscapes y, en particular de:</p>
      <ul>
        <li><p>La utilización que resulte contraria a las leyes españolas o que infrinja los derechos de terceros.</p>
        </li>
        <li><p>La publicación o la transmisión de cualquier contenido que, a juicio de Sahara Soundscapes, resulte
          violento, obsceno, abusivo, ilegal, racial, xenófobo o difamatorio.</p>
        </li>
        <li><p>Los cracks, números de serie de programas o cualquier otro contenido que vulnere derechos de la propiedad intelectual de terceros.</p>
        </li>
        <li><p>La recogida y/o utilización de datos personales de otros usuarios sin su consentimiento expreso o contraviniendo lo dispuesto en Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección de las personas físicas en lo que respecta al tratamiento de datos personales y a la libre circulación de los mismos.</p>
        </li>
        <li><p>La utilización del servidor de correo del dominio y de las direcciones de correo electrónico para el envío de correo masivo no deseado.
          El usuario tiene toda la responsabilidad sobre el contenido de su web, la información transmitida y almacenada, los enlaces de hipertexto, las reivindicaciones de terceros y las acciones legales en referencia a propiedad intelectual, derechos de terceros y protección de menores.
          El usuario es responsable respecto a las leyes y reglamentos en vigor y las reglas que tienen que ver con el funcionamiento del servicio online, comercio electrónico, derechos de autor, mantenimiento del orden público, así como principios universales de uso de Internet.
          El usuario indemnizará a Sahara Soundscapes por los gastos que generara la imputación de Sahara Soundscapes en alguna causa cuya responsabilidad fuera atribuible al usuario, incluidos honorarios y gastos de defensa jurídica, incluso en el caso de una decisión judicial no definitiva.</p>
        </li>
      </ul>
      <p><strong>Protección de la información alojada</strong>
        Sahara Soundscapes realiza copias de seguridad de los contenidos alojados en sus servidores, sin embargo no se responsabiliza de la pérdida o el borrado accidental de los datos por parte de los usuarios. De igual manera, no garantiza la reposición total de los datos borrados por los usuarios, ya que los citados datos podrían haber sido suprimidos y/o modificados durante el periodo del tiempo transcurrido desde la última copia de seguridad.
        Sahara Soundscapes no se responsabiliza de las pérdidas de contenidos alojados en sus servidores.</p>
      <p><strong>Comunicaciones comerciales</strong></p>
      <p>En aplicación de la LSSI. Sahara Soundscapes no enviará comunicaciones publicitarias o promocionales por correo electrónico u otro medio de comunicación electrónica equivalente que previamente no hubieran sido solicitadas o expresamente autorizadas por los destinatarios de las mismas.</p>
      <p>En todo caso, el usuario, tras acreditar su identidad, podrá solicitar que no se le haga llegar más información comercial a través de los canales de Atención al Cliente.</p>

    </div>
  </div>
  <div class="row">
    <div class="col text-center">
      <button (click)="modal.dismiss('Cross click')" class="btn btn-primary">Cerrar</button>
    </div>
  </div>
</div>
