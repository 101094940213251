import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EqualValidatorDirective} from '@modules/shared/validators/is-equal.directive';
import {CustomMinDirective} from '@modules/shared/validators/custom-min.directive';
import {CustomMaxDirective} from '@modules/shared/validators/custom-max.directive';
import {RouterModule} from '@angular/router';
import {ModalThreeButtonsComponent} from '@modules/shared/modal/modal-three-buttons.component';
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime-ex';
import { AnnouncementItemComponent } from './components/announcement-item/announcement-item.component';
import {ModalTextComponent} from '@modules/shared/modal/modal-text.component';
import { ResourcesListComponent } from './components/resources-list/resources-list.component';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { ViewPhotoComponent } from './components/multimedia/view-photo/view-photo.component';
import { ViewAudioComponent } from './components/multimedia/view-audio/view-audio.component';
import {VimeModule} from '@vime/angular';
import { ViewVideoComponent } from './components/multimedia/view-video/view-video.component';
import { ViewDocComponent } from './components/multimedia/view-doc/view-doc.component';
import {NgxDocViewerModule} from 'ngx-doc-viewer';
import {ModalMapComponent} from '@modules/shared/modal/modal-map.component';
import { ModalAboutComponent } from './modal/modal-about/modal-about.component';
import { PrivacyTermsComponent } from './components/privacity-terms/privacy-terms.component';
import { MiniMapComponent } from './components/mini-map/mini-map.component';
import {TranslocoRootModule} from '@app/transloco-root.module';
import { CommonTableComponent } from './components/common-table/common-table.component';
import { BoardAnnouncementsListComponent } from './components/announcements-list/board-announcements-list.component';
import { RelevantLinksComponent } from '../board/relevant-links/relevant-links.component';
import { RelevantLinksListComponent } from './components/relevant-links-list/relevant-links-list.component';
import { ViewResourceComponent } from './components/view-resource/view-resource.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxDatatableModule,
    VimeModule,
    NgxDocViewerModule,
    TranslocoRootModule,
  ],
    declarations: [
        EqualValidatorDirective,
        CustomMinDirective,
        CustomMaxDirective,
        ModalThreeButtonsComponent,
        ModalTextComponent,
        AnnouncementItemComponent,
        ResourcesListComponent,
        ViewPhotoComponent,
        ViewAudioComponent,
        ViewVideoComponent,
        ViewDocComponent,
        ModalMapComponent,
        ModalAboutComponent,
        PrivacyTermsComponent,
        MiniMapComponent,
        CommonTableComponent,
        BoardAnnouncementsListComponent,
        RelevantLinksListComponent,
        ViewResourceComponent
    ],
    exports: [
        FormsModule,
        CommonModule,
        NgbModule,
        OwlDateTimeModule,
        OwlNativeDateTimeModule,
        EqualValidatorDirective,
        CustomMinDirective,
        CustomMaxDirective,
        ModalTextComponent,
        ModalThreeButtonsComponent,
        AnnouncementItemComponent,
        ResourcesListComponent,
        ViewPhotoComponent,
        ViewAudioComponent,
        ViewVideoComponent,
        ViewDocComponent,
        ModalMapComponent,
        MiniMapComponent,
      TranslocoRootModule,
      CommonTableComponent,
      BoardAnnouncementsListComponent,
      RelevantLinksListComponent,
      ViewResourceComponent
    ],
  entryComponents: [
    ModalThreeButtonsComponent,
    ModalMapComponent
  ]
})

export class SharedModule { }
