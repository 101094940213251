<ng-container *transloco="let t; read: 'common'">

<div class="row">
    <div class="col col-12">
      <ngx-datatable #table
        class="material"
        [rows]="rows"
        [columns]="columns"
        [limit]="10"
        [footerHeight]="50"
        [columnMode]="'standard'"
        [headerHeight]="50"
                     [scrollbarH]="true"
        rowHeight="auto"
      >
      </ngx-datatable>
      <button *ngIf="showAddButton" style="margin-top: 1.5rem;" type="button" class="btn btn-primary small-button" (click)="addMethod()">{{t('add')}}</button>
      <button *ngIf="showRefreshButton" style="margin-top: 1.5rem;" type="button" class="btn btn-primary small-button" (click)="refreshMethod()">{{t('refresh')}}</button>
      <ng-template #editTmpl let-column="column" let-row="row" let-value="value">
        <div>
          <a class="button-table-delete" style="margin-left: 1rem; color: #e82c2c" [routerLink]=""  (click)="deleteMethod(row['id'])"><i class="fa fa-lg fa-trash"></i></a>
        </div>
      </ng-template>
    </div>
  </div>
  </ng-container>