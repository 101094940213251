import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'ngbd-modal-confirm',
  template: `
  <div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="modal-body">
      <p><strong>{{mainText}}</strong></p>
    </div>
    <div class="form-row">
      <div class="form-group col-12">
        <textarea [(ngModel)]="comments" class="form-control" id="comments"
                  name="comments" rows="4"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close('cancel')">{{buttonLeft}}</button>
    <button type="button" class="btn btn-danger" (click)="modal.close(comments)">{{buttonRight}}</button>
  </div>
  `
})

export class ModalTextComponent {
  type: string;
  mainText = '';
  title = '';
  buttonRight = '';
  buttonLeft = '';
  comments = '';

  constructor(public modal: NgbActiveModal) { }
}
