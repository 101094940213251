import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '@env/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiService {

  private url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.api.baseUrl;
  }
  // Auth
  public login(params) {
    return this.httpClient.post(`${this.url}/login/`, params, {withCredentials: environment.api.withCredentials});
  }
  public logout(): Observable<any> {
    return this.httpClient.get(`${this.url}/logout/`);
  }
  public checkToken(): Observable<any> {
    return this.httpClient.get(`${this.url}/user/check_token`);
  }
  public sendResetPasswordEmail(params: any): Observable<any> {
    return this.httpClient.post(`${this.url}/send_reset_password`, params);
  }
  public resetPassword(params: any): Observable<any> {
    return this.httpClient.post(`${this.url}/reset_password`, params);
  }
  // Users
  public changeMyPassword(params): Observable<any> {
    return this.httpClient.post(`${this.url}/user/change_my_password`, params);
  }
  public changePassword(params): Observable<any> {
    return this.httpClient.post(`${this.url}/user/change_password`, params);
  }
  public getUsernames(): Observable<any> {
    return this.httpClient.get(`${this.url}/user/list`);
  }
  public createUser(data: any): Observable<any> {
    return this.httpClient.post(`${this.url}/user/`, data);
  }
  public getMyProfile(): Observable<any> {
    return this.httpClient.get(`${this.url}/user/my_profile`);
  }
  public sendMyProfile(data: any): Observable<any> {
    return this.httpClient.put(`${this.url}/user/my_profile`, data);
  }
  public getProfile(id: number): Observable<any> {
    return this.httpClient.get(`${this.url}/user/${id}`);
  }
  public sendProfile(id: number, data: any): Observable<any> {
    return this.httpClient.put(`${this.url}/user/${id}`, data);
  }
  public deleteMyProfile(mode: string): Observable<any> {
    return this.httpClient.get(`${this.url}/user/delete_my_profile/${mode}`);
  }
  public createArtistRequest(): Observable<any> {
    return this.httpClient.get(`${this.url}/artist_request/`);
  }
  public deleteProfile(id: number, mode: string): Observable<any> {
    return this.httpClient.get(`${this.url}/user/delete_user/${id}/${mode}`);
  }
  public getUserAccounts(): Observable<any> {
    return this.httpClient.get(`${this.url}/user/`);
  }
  public getRegisterRequests(): Observable<any> {
    return this.httpClient.get(`${this.url}/register_requests/`);
  }
  public rejectRegisterRequests(id: number): Observable<any> {
    return this.httpClient.get(`${this.url}/reject_register_request/${id}`);
  }
  public acceptRegisterRequests(id: number): Observable<any> {
    return this.httpClient.get(`${this.url}/accept_register_request/${id}`);
  }
  public getArtistRequests(): Observable<any> {
    return this.httpClient.get(`${this.url}/artist_requests/`);
  }
  public rejectArtistRequests(id: number): Observable<any> {
    return this.httpClient.get(`${this.url}/reject_artist_request/${id}`);
  }
  public acceptArtistRequests(id: number): Observable<any> {
    return this.httpClient.get(`${this.url}/accept_artist_request/${id}`);
  }
  public changeUserLanguage(data: any): Observable<any> {
    return this.httpClient.put(`${this.url}/user/change_language`, data);
  }
  // Announcements
  public getBoard(): Observable<any>  {
    return this.httpClient.get(`${this.url}/announcement/`);
  }
  public getMyAnnouncements(mode: string): Observable<any>  {
    return this.httpClient.get(`${this.url}/my_announcements/${mode}`);
  }
  public createAnnouncement(data: FormData): Observable<any> {
    return this.httpClient.post(`${this.url}/announcement/`, data);
  }
  public editAnnouncement(id: number, data: FormData): Observable<any> {
    return this.httpClient.put(`${this.url}/announcement/${id}`, data);
  }
  public getAnnouncement(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/announcement/${id}`);
  }
  public deleteAnnouncement(id: number): Observable<any>  {
    return this.httpClient.delete(`${this.url}/announcement/${id}`);
  }
  public acceptAnnouncement(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/announcement/accept/${id}`);
  }
  public rejectAnnouncement(id: number, params: any): Observable<any>  {
    return this.httpClient.put(`${this.url}/announcement/reject/${id}`, params);
  }
  // Resources
  public downloadResource(idResource: number): Observable<any> {
    return this.httpClient.get(`${this.url}/resource/download/${idResource}`, {responseType: 'blob'});
  }
  public getAllResources(): Observable<any>  {
    return this.httpClient.get(`${this.url}/resource/`);
  }
  public quickSearch(data: any): Observable<any> {
    return this.httpClient.post(`${this.url}/resource/quick_search`, data);
  }
  public advancedSearch(data: any): Observable<any> {
    return this.httpClient.post(`${this.url}/resource/advanced_search`, data);
  }
  public getResource(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/resource/${id}`);
  }
  public getMyResources(mode: string): Observable<any>  {
    return this.httpClient.get(`${this.url}/my_resources/${mode}`);
  }
  public createResource(data: FormData): Observable<any> {
    return this.httpClient.post(`${this.url}/resource/`, data, {reportProgress: true, observe: 'events' });
  }
  public editResource(data: FormData): Observable<any> {
    return this.httpClient.put(`${this.url}/resource/`, data);
  }
  public deleteResource(id: number): Observable<any>  {
    return this.httpClient.delete(`${this.url}/resource/${id}`);
  }
  public acceptResource(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/resource/accept/${id}`);
  }
  public rejectResource(id: number, params: any): Observable<any>  {
    return this.httpClient.put(`${this.url}/resource/reject/${id}`, params);
  }
  public addResourceComment(resourceId: number, data: any): Observable<any> {
    return this.httpClient.post(`${this.url}/resource/${resourceId}/comment`, data);
  }
  public deleteResourceComment(commentId: number): Observable<any> {
    return this.httpClient.delete(`${this.url}/resource/comment/${commentId}`);
  }
  // Setting
  public getSettingValue(key: string): Observable<any>  {
    return this.httpClient.get(`${this.url}/setting/${key}`);
  }
  public getSettings(): Observable<any>  {
    return this.httpClient.get(`${this.url}/setting/`);
  }
  public getSocialSettings(): Observable<any>  {
    return this.httpClient.get(`${this.url}/setting/social`);
  }
  public setSettingValue(params: any): Observable<any>  {
    return this.httpClient.post(`${this.url}/setting/`, params);
  }
  public addCommonPlace(params: any): Observable<any>  {
    return this.httpClient.post(`${this.url}/setting/common_place`, params);
  }
  public getCommonPlaces(): Observable<any>  {
    return this.httpClient.get(`${this.url}/setting/common_place`);
  }
  public deleteCommonPlace(id): Observable<any>  {
    return this.httpClient.delete(`${this.url}/setting/common_place/${id}`);
  }
  public addCategory(params: any): Observable<any>  {
    return this.httpClient.post(`${this.url}/setting/category`, params);
  }
  public deleteCategory(id): Observable<any>  {
    return this.httpClient.delete(`${this.url}/setting/category/${id}`);
  }
  public getCategories(): Observable<any>  {
    return this.httpClient.get(`${this.url}/setting/categories`);
  }
  // Messages
  public getMessages(mode: string): Observable<any>  {
    return this.httpClient.get(`${this.url}/message/${mode}`);
  }
  public getUnreadMessages(): Observable<any>  {
    return this.httpClient.get(`${this.url}/message/unread`);
  }
  public getMessageBody(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/message/body/${id}`);
  }
  public deleteMessage(id: number): Observable<any>  {
    return this.httpClient.delete(`${this.url}/message/${id}`);
  }
  public sendMessage(params: any): Observable<any>  {
    return this.httpClient.post(`${this.url}/message/`, params);
  }
  // RelevantLinks
  public getRelevantLinks(): Observable<any>  {
    return this.httpClient.get(`${this.url}/relevant_link/`);
  }
  public getMyRelevantLinks(mode: string): Observable<any>  {
    return this.httpClient.get(`${this.url}/my_relevant_links/${mode}`);
  }
  public createRelevantLink(params: any): Observable<any> {
    return this.httpClient.post(`${this.url}/relevant_link/`, params);
  }
  public editRelevantLink(id: number, params: any): Observable<any> {
    return this.httpClient.put(`${this.url}/relevant_link/${id}`, params);
  }
  public getRelevantLink(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/relevant_link/${id}`);
  }
  public deleteRelevantLink(id: number): Observable<any>  {
    return this.httpClient.delete(`${this.url}/relevant_link/${id}`);
  }
  public acceptRelevantLink(id: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/relevant_link/accept/${id}`);
  }
  public rejectRelevantLink(id: number, params: any): Observable<any>  {
    return this.httpClient.put(`${this.url}/relevant_link/reject/${id}`, params);
  }
  // System
  public getSystemResourcesUsage(): Observable<any>  {
    return this.httpClient.get(`${this.url}/system/resources_usage`);
  }
  public canStoreFileBySize(size: number): Observable<any>  {
    return this.httpClient.get(`${this.url}/system/can_store/${size}`);
  }
  public getCurrentTheme(): Observable<any>  {
    return this.httpClient.get(`${this.url}/system/theme`);
  }
  public getCustom(): Observable<any>  {
    return this.httpClient.get(`${this.url}/system/custom`);
  }
  public getGeneralStats(): Observable<any>  {
    return this.httpClient.get(`${this.url}/system/stats/general`);
  }
}

