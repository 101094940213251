import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild} from '@angular/core';
import {ApiService} from '@modules/shared/services/api.service';
import {mobileWidth, roles, typeLabels} from '@modules/shared/shared.component';
import {ToastrService} from 'ngx-toastr';
import {TranslocoService} from '@ngneat/transloco';
import { ModalThreeButtonsComponent } from '../../modal/modal-three-buttons.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-resources-list',
  templateUrl: './resources-list.component.html',
  styleUrls: ['./resources-list.component.css']
})
export class ResourcesListComponent implements OnInit, OnChanges {
  @Input() resources;
  @Input() mode;
  @Output() refreshData = new EventEmitter<string>();
  @ViewChild('editTmpl', {static: true}) editTmpl: TemplateRef<any>;
  @ViewChild('imgTmpl', {static: true}) imgTmpl: TemplateRef<any>;
  @ViewChild('titleTmpl', {static: true}) titleTmpl: TemplateRef<any>;
  @ViewChild('infoTmpl', {static: true}) infoTmpl: TemplateRef<any>;
  @ViewChild('mobileInfoTmpl', {static: true}) mobileInfoTmpl: TemplateRef<any>;
  @ViewChild('table', {static: true}) ngxTable;
  rows;
  columns;
  public getScreenWidth: any;
  public getScreenHeight: any;
  constructor(private apiService: ApiService,
     private translocoService: TranslocoService,
      private toastr: ToastrService, 
      private modalService: NgbModal) {
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.generateTable(this.resources);
  }

  ngOnInit(): void {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if (this.mode === 'moderate') {
      this.apiService.getMyResources(this.mode).subscribe(response => {
        if (response.success) {
          this.resources = response.data.moderate;
          this.resources.map(r => r.shownType = typeLabels[r.multimedia_type]);
          this.generateTable(this.resources);
        }
      });
    } else {
      this.generateTable(this.resources);
    }
  }
  getTypeLabels(type: string) {
    if (type === 'photo') {
      return this.translocoService.translate('common.photo');
    } else if (type === 'audio') {
      return this.translocoService.translate('common.audio');
    } else if (type === 'video') {
      return this.translocoService.translate('common.video');
    } else if (type === 'document') {
      return this.translocoService.translate('common.document');
    }
  }
  generateTable(data) {
    this.rows = data;
    if (window.innerWidth < mobileWidth){
      this.columns = [
        {
          cellTemplate: this.imgTmpl,
          name: '',
          sortable: false,
          width: window.innerWidth < mobileWidth ?  100 : 200
  
        },
        {
          cellTemplate: this.mobileInfoTmpl,
          cellClass: 'resource-table-cell',
          name: '',
          sortable: false,
          width: window.innerWidth < mobileWidth ?  280 : 900
  
        },
      ];
    } else {
      this.columns = [
        {
          cellTemplate: this.imgTmpl,
          name: '',
          sortable: false,
          width: window.innerWidth < mobileWidth ?  100 : 200
  
        },
        {
          cellTemplate: this.titleTmpl,
          cellClass: 'resource-table-cell',
          name: '',
          sortable: false,
          width: window.innerWidth < mobileWidth ?  130 : 400
  
        },
        {
          cellTemplate: this.infoTmpl,
          name: '',
          sortable: false,
          width: window.innerWidth < mobileWidth ? 150 : 500
  
        },
      ];
    }

  }
  getRowClass(row) {
    return 'resource-table-row';
  }
  onClickDelete(resourceId) {
    const modal = this.modalService.open(ModalThreeButtonsComponent);
    modal.componentInstance.title = this.translocoService.translate('common.delete_resource');
    modal.componentInstance.mainText = this.translocoService.translate('common.confirm_delete_resource');
    modal.componentInstance.dangerText = this.translocoService.translate('common.danger_action');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.no');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.yes_delete');
    modal.result.then((data) => {
      if (data === 'buttonRight') {
        this.apiService.deleteResource(resourceId).subscribe(response => {
          if (response.success) {
            this.toastr.success('', this.translocoService.translate('common.resource_deleted'));
            this.refreshData.emit('refresh');
          }
        }, error => {
          this.toastr.error('', this.translocoService.translate('common.internal_error'));
        });
      }
    });
  }
  showManageButtons() {
    if (this.mode === 'my_contributions') {
      return true;
    }
    if ((+localStorage.getItem('id_role') === roles.ADMINISTRADOR || +localStorage.getItem('id_role') === roles.EXPERTO) && this.mode !== 'moderate' ) {
      return true;
    }
    return false
  }
}
