<div style="margin-top: 1.5rem;" class="row">
  <div class="col col-12">
    <ngx-datatable #table
                   class="material"
                   [rows]="rows"
                   [columns]="columns"
                   [limit]="10"
                   [footerHeight]="50"
                   [columnMode]="'standard'"
                   [headerHeight]="50"
                   [scrollbarH]="true"
                   rowHeight="auto"
                   [rowClass]="getRowClass "
    >
    </ngx-datatable>
    <!-- <ng-template #editTmpl let-column="column" let-row="row" let-value="value">
      <a  class="" [routerLink]="['/recursos/ver/', row['id']]"><i class="fa fa-lg fa-eye"></i></a>
      <a *ngIf="mode === 'moderar'" class="" [routerLink]="['/moderar/recurso/', row['id']]"><i class="fa fa-lg fa-gavel"></i></a>
    </ng-template> -->

    <ng-template class="cellcontainer" #imgTmpl let-row="row" let-value="value">
      <div style="height: 100%;">
        <img *ngIf="row.multimedia_type === 'photo'" style="width: 100%;" src={{row.url}} />
        <img *ngIf="row.multimedia_type === 'audio'" style="width: 100%;" src="assets/images/music.jpg" />
        <img *ngIf="row.multimedia_type === 'video'" style="width: 100%;" src="assets/images/video.jpg" />
        <img *ngIf="row.multimedia_type === 'document'" style="width: 100%;" src="assets/images/document.png" />
      </div>

    </ng-template>

    <ng-template #titleTmpl let-row="row" let-value="value">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- Div para el título -->
                <h4>{{row.title}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- Div para la descripción -->
                <p>{{row.description}}</p>
            </div>
        </div>
    </div>
    </ng-template>
    <ng-template #infoTmpl let-row="row" let-value="value">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- Div para el usuario que publicó el recurso -->
                <p><strong>Usuario:</strong> {{row.username}}</p>
            </div>
            <div class="col-md-12">
                <!-- Div para la fecha de publicación -->
                <p><strong>Fecha de Publicación:</strong> {{row.publication_date}}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- Div para las categorías del recurso -->
                <p><strong>Categorías:</strong> {{row.categories ? row.categories :  'Sin categorías'}}</p>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <a *ngIf="mode !== 'moderate'" class="" [routerLink]="['/recursos/ver/', row['id']]"><i class="fa fa-lg fa-eye"></i> Ver recurso</a>
            <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #ccb30c" [routerLink]="['/recursos/editar/', row['id']]"><i class="fa fa-lg fa-pencil"></i></a>
            <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #e82c2c" [routerLink]="" (click)="onClickDelete(row['id'])"><i class="fa fa-lg fa-trash"></i></a>
            <a *ngIf="mode === 'moderate'" class=""  [routerLink]="['/moderar/recurso/', row['id']]"><i class="fa fa-lg fa-gavel"></i> Moderar</a>
          </div>
      </div>
    </div>
    </ng-template>
    <ng-template #mobileInfoTmpl let-row="row" let-value="value">
      <div class="container">
        <div class="row">
            <div class="col-md-12">
                <!-- Div para el título -->
                <h4>{{row.title}}</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <!-- Div para la descripción -->
                <p>{{row.description}}</p>
            </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <!-- Div para el usuario que publicó el recurso -->
              <p><strong>Usuario:</strong> {{row.username}}</p>
          </div>
          <div class="col-md-12">
              <!-- Div para la fecha de publicación -->
              <p><strong>Fecha de Publicación:</strong> {{row.publication_date}}</p>
          </div>
      </div>
      <div class="row">
          <div class="col-md-12">
              <!-- Div para las categorías del recurso -->
              <p><strong>Categorías:</strong> {{row.categories ? row.categories :  'Sin categorías'}}</p>
          </div>
      </div>
      <div class="row">
        <div class="col-md-12">
            <!-- Div para las categorías del recurso -->
            <a *ngIf="mode !== 'moderate'" class="" [routerLink]="['/recursos/ver/', row['id']]"><i class="fa fa-lg fa-eye"></i> Ver recurso</a>
            <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #ccb30c" [routerLink]="['/recursos/editar/', row['id']]"><i class="fa fa-lg fa-pencil"></i></a>
            <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #e82c2c" [routerLink]="" (click)="onClickDelete(row['id'])"><i class="fa fa-lg fa-trash"></i></a>
            <a *ngIf="mode === 'moderate'" class="" style="margin-left: 1rem; color: #52e82c" [routerLink]="['/moderar/recurso/', row['id']]"><i class="fa fa-lg fa-gavel"></i></a>
        </div>
    </div>
    </div>
    </ng-template>
  </div>
</div>
