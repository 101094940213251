<div style="margin-top: 1.5rem;" class="row">
    <div class="col col-12">
      <ngx-datatable #table
                     class="material"
                     [rows]="rows"
                     [columns]="columns"
                     [limit]="10"
                     [footerHeight]="50"
                     [columnMode]="'force'"
                     [headerHeight]="50"
                     [scrollbarH]="true"
                     rowHeight="auto"
                     [rowClass]="getRowClass "
      >
      </ngx-datatable>

  
      <ng-template class="cellcontainer" #imgTmpl let-row="row" let-value="value">
        <div style="height: 100%;">
          <img style="width: 100%;" src={{row.url}} />
        </div>
  
      </ng-template>
  
      <ng-template #titleTmpl let-row="row" let-value="value">
        <div sty class="container">
          <div class="row">
              <div style="word-wrap: break-word" class="col-12">
                  <!-- Div para el título -->
                  <h4>{{row.title}}</h4>
              </div>
          </div>
          <div class="row">
              <div class="col-md-12">
                  <!-- Div para la descripción -->
                  <p>{{row.body}}</p>
              </div>
          </div>
          <div class="row">
            <div class="col col-12 text-center">
                <img class="mx-auto" width="150" src={{row.url}} />
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-4" style="font-size: 0.8rem;">
              <!-- Div para el título -->
              <div><b>Publicado por:</b> {{row.username}}</div>
              <div *ngIf="mode === 'board'"><b>Fecha:</b> {{row.publication_date}}</div>
              <div style="margin-top: 1rem;">
                <a *ngIf="mode === 'moderate'" class=""  [routerLink]="['/moderar/anuncio/', row['id']]"><i class="fa fa-lg fa-gavel"></i> Moderar</a>
                <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #ccb30c" [routerLink]="['/anuncios/editar/', row['id']]"><i class="fa fa-lg fa-pencil"></i></a>
                <a *ngIf="showManageButtons()" class="" style="margin-left: 1rem; color: #e82c2c" [routerLink]="" (click)="onClickDelete(row['id'])"><i class="fa fa-lg fa-trash"></i></a>
              </div>
          </div>
        </div>
      </div>
      </ng-template>
    </div>
  </div>
  