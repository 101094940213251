import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true}]
})
export class CustomMaxDirective implements Validator {
  @Input()
  customMax: number;
  formValue: number;
  
  validate(c: FormControl): {[key: string]: any} {
    if (c.value == null) {
      return null
    } else if (isNaN(c.value)) {
      return {"isNan": true}
    } else {
      this.formValue = Number(c.value)
    }
    return ( this.formValue > this.customMax)? {"customMax": true} : null;
  }
}

