import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import {showItem} from '@modules/shared/shared.component';

@Injectable()
export class RoleGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('isLoggedin') === 'false') {
          this.router.navigate(['/login']);
          return false;
        }

        const allow = showItem(route.data['name']);
        if (localStorage.getItem('isLoggedin') === 'true' && allow) {
            return true;
        }

        this.router.navigate(['/explorar']);
        return false;
    }
}
