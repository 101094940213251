<div id="container">
  <vm-player (vmReady)="onPlayerReady()" controls>

    <vm-video  *ngIf="resource.source_type === 'file'" >
      <source data-src="{{resource.url}}"/>
    </vm-video>

    <vm-youtube *ngIf="resource.source_type === 'youtube'" cookies="true" [videoId]="youtubeId"></vm-youtube>

  </vm-player>
</div>

<style>
  :host {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #container {
    width: 100%;
    max-width: 960px;
  }
</style>

