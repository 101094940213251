import { Component, Input, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiService} from '@modules/shared/services/api.service';
import {ToastrService} from 'ngx-toastr';
import { saveAs } from 'file-saver';
import {apiDateToString, typeLabels} from '@modules/shared/shared.component';
import {TranslocoService} from '@ngneat/transloco';
import { ModalThreeButtonsComponent } from '@app/modules/shared/modal/modal-three-buttons.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalTextComponent } from '../../modal/modal-text.component';
@Component({
  selector: 'app-view-resource',
  templateUrl: './view-resource.component.html',
  styleUrls: ['./view-resource.component.css']
})
export class ViewResourceComponent implements OnInit {
  typeLabels = typeLabels;
  resource;
  idResource;
  isDownloading = false;
  commentContent = '';
  mode;
  constructor(private route: ActivatedRoute,
              private apiService: ApiService,
              private toastr: ToastrService,
              private modalService: NgbModal,
              private translocoService: TranslocoService,
              private router: Router) { }

  ngOnInit(): void {
    this.getResource();
  }

  getResource() {
    this.mode = this.route.snapshot.data.mode
    const routeParams = this.route.snapshot.paramMap;
    this.idResource = routeParams.get('id');
    if (this.idResource) {
      this.apiService.getResource(this.idResource).subscribe(response => {
        this.resource = response.data;
        const c = this.resource.coordinates.split(',');
        const cn = [+(c[0]).trim(), +c[1]];
        this.resource.coordinates = `${Math.trunc(cn[0] * Math.pow(10, 4)) / Math.pow(10, 4)}, ${Math.trunc(cn[1] * Math.pow(10, 4)) / Math.pow(10, 4)}`;
        this.resource.shownCoordinates = this.resource.coordinates.toString();
        if (this.resource.common_place && this.resource.common_place !== '') {
          this.resource.shownCoordinates += ` (${this.resource.common_place})`;
        }
        this.resource.categories = this.resource.categories ?  this.resource.categories.split('|').join(', ') : '';
        this.resource.comments.map((comment) => {
          comment.parsedDate = apiDateToString(comment.creation_date);
          comment.avatar_url = comment.user_avatar ? comment.user_avatar : 'assets/images/default_avatar.png';
        });
      }, error => {
        this.toastr.error('', this.translocoService.translate('common.internal_error'));
      });
    }
  }
  downloadResource() {
    this.isDownloading = true;
    this.apiService.downloadResource(this.resource.id).subscribe(response => {
      if (!response.hasOwnProperty('success')) {
        const blob = new Blob([response]);
        const items = this.resource.url.split('/');
        saveAs(blob, items[items.length - 1]);
      } else {
        this.toastr.error(this.translocoService.translate('common.internal_error'));
      }
      this.isDownloading = false;
    });
  }
  getResourceShownKeys(): string[] {
    const keys = Object.keys(this.resource);
    console.log(keys);
    return keys.filter(key => key !== 'id');
  }
  addComment() {
    const params = {
      'content': this.commentContent
    }
    this.apiService.addResourceComment(this.resource.id, params).subscribe(response => {
      if (response.success) {
        this.toastr.success(this.translocoService.translate('Comentario añadido con éxito'));
        this.commentContent = '';
        this.getResource();
      } else {
        this.toastr.error(this.translocoService.translate('common.internal_error'));
      }
    });
  }
  deleteComment(idComment) {

    const modal = this.modalService.open(ModalThreeButtonsComponent);
    modal.componentInstance.title = this.translocoService.translate('common.delete_comment');
    modal.componentInstance.mainText = this.translocoService.translate('common.confirm_delete_comment');
    modal.componentInstance.dangerText = this.translocoService.translate('common.danger_action');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.no');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.yes_delete');
    modal.result.then((data) => {
      if (data === 'buttonRight') {
        this.apiService.deleteResourceComment(idComment).subscribe(response => {
          if (response.success) {
            this.toastr.success(this.translocoService.translate('common.comment_deleted'));
            this.getResource();
          } else {
            this.toastr.error(this.translocoService.translate('common.internal_error'));
          }
        });
      }
    });
  }
  onClickAccept() {
    this.apiService.acceptResource(this.idResource).subscribe(response => {
      if (response.success) {
        this.toastr.success('', this.translocoService.translate('common.resource_published'));
        this.router.navigate(['/moderar']);
      }
    }, error => {
      this.toastr.error('', this.translocoService.translate('common.internal_error'));
    });
  }
  onClickReject() {
    const modal = this.modalService.open(ModalTextComponent);
    modal.componentInstance.title = this.translocoService.translate('common.reject_resource');
    modal.componentInstance.mainText = this.translocoService.translate('common.rejection_comments');
    modal.componentInstance.buttonLeft = this.translocoService.translate('common.cancel');
    modal.componentInstance.buttonRight = this.translocoService.translate('common.reject');
    modal.result.then((data) => {
      if (data !== 'cancel') {
        const params = {'comments': data};
        this.apiService.rejectResource(this.idResource, params).subscribe(response => {
          if (response.success) {
            this.toastr.success('', this.translocoService.translate('common.resource_rejected'));
            this.router.navigate(['/moderar']);
          }
        }, error => {
          this.toastr.error('', this.translocoService.translate('common.internal_error'));
        });
      }
    });
  }
}
