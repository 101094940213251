<ng-container *transloco="let t; read: 'common'">

  <div *ngIf="mode === 'moderate'" class="container">
    <div *ngIf="resource" style="margin-top: 1rem;" class="row">
      <div class="col col-12 text-center">
        <button type="button" class="btn btn-success" (click)="onClickAccept()">{{t('publish')}}&nbsp;<i class="fa fa-lg fa-thumbs-up "></i>  </button>
        <button  style="margin-left: 0.5rem;" type="button" class="btn btn-danger" (click)="onClickReject()">{{t('reject')}}&nbsp;<i class="fa fa-lg fa-thumbs-down"></i> </button>
      </div>
    </div>
  </div>

<div *ngIf="resource" style="margin-top: 2rem; margin-bottom: 2rem;" class="row">
<div class="col col-12">
  <app-view-photo [resource]="resource" *ngIf="resource.multimedia_type === 'photo'"></app-view-photo>
  <app-view-audio [resource]="resource" *ngIf="resource.multimedia_type === 'audio'"></app-view-audio>
  <app-view-video [resource]="resource" *ngIf="resource.multimedia_type === 'video'"></app-view-video>
  <app-view-doc [resource]="resource" *ngIf="resource.multimedia_type === 'document'"></app-view-doc>
</div>


</div>

<hr>

<div class="container">

  <div *ngIf="resource" style="margin-top: 2rem;" class="my-panel row">
    <div class="col col-12 col-lg-6">
      <div class="attributes">
        <div id="title" class="attribute">
          <span class="attribute-name">{{t('title')}}</span>
          <span class="attribute-value">{{resource.title}}</span>
        </div>
        <div class="attribute" id="format" *ngIf="resource.format">
          <span class="attribute-name">{{t('format')}}</span>
          <span class="attribute-value">{{resource.format}}</span>
        </div>
        <div class="attribute" id="author" *ngIf="resource.author">
          <span class="attribute-name">{{t('author')}}</span>
          <span class="attribute-value">{{resource.author}}</span>
        </div>
        <div class="attribute" id="categories" *ngIf="resource.categories">
          <span class="attribute-name">{{t('categories')}}</span>
          <span class="attribute-value">{{resource.categories}}</span>
        </div>
        <div class="attribute" id="license" *ngIf="resource.rights || resource.rights === 'NO'">
          <span class="attribute-name">{{t('license')}}</span>
          <span class="attribute-value">{{resource.rights === 'NO' ? 'Sin licencia' : resource.rights}}</span>
        </div>
        <div class="attribute" id="url_to_quote" *ngIf="resource.url">
          <span class="attribute-name">{{t('url_to_quote')}}</span>
          <span class="attribute-value">{{resource.url}}</span>
        </div>
        <div class="attribute" id="keywords" *ngIf="resource.keywords">
          <span class="attribute-name">{{t('key_words')}}</span>
          <span class="attribute-value">{{resource.keywords}}</span>
        </div>
        <div class="attribute" id="source" *ngIf="resource.source">
          <span class="attribute-name">{{t('source')}}</span>
          <span class="attribute-value">{{resource.source}}</span>
        </div>
        <div class="attribute" id="publisher" *ngIf="resource.publisher">
          <span class="attribute-name">{{t('publisher')}}</span>
          <span class="attribute-value">{{resource.publisher}}</span>
        </div>
        <div class="attribute" id="coverage" *ngIf="resource.coverage">
          <span class="attribute-name">{{t('coverage')}}</span>
          <span class="attribute-value">{{resource.coverage}}</span>
        </div>
        <div class="attribute" id="resource_type" *ngIf="resource.multimedia_type || typeLabels[resource.multimedia_type]">
          <span class="attribute-name">{{t('resource_type')}}</span>
          <span class="attribute-value">{{typeLabels[resource.multimedia_type]}}</span>
        </div>
      </div>
    </div>
    <div class="col col-12 col-lg-6">
      <div>
        <span class="attribute-name">{{t('description')}}</span>
        <br>
        <span class="attribute-value">{{resource.description}}</span>
      </div>
      <div style="margin-top: 2rem;">
        <app-mini-map [coordinates]="resource.coordinates"></app-mini-map>
      </div>
      <div *ngIf="resource.download && resource.url.includes('resources/soundscapes')" style="margin-left: 0.5rem;" class="row">
        <div class="col col-3">
          <button *ngIf="!isDownloading" (click)="downloadResource()" type="button" class="btn btn-primary">{{t('download')}}</button>
          <button *ngIf="isDownloading" class="btn btn-primary" type="button" disabled>
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            {{t('downloading')}}...
          </button>
        </div>
      </div>
    </div>
  </div>
  
</div>

<hr>

<div *ngIf="resource && mode === 'view' && resource.publication_date" class="container">
  <div *ngIf="(resource.comments).length > 0" class="my-panel row">
    <div class="col col-12 ">
      <h2>Comentarios</h2>
      <ul>
          <li *ngFor="let comment of resource.comments">
              <div class="comment">
                  <div class="user-avatar">
                    <div>
                      <img src="{{comment.avatar_url}}" alt="Avatar del Usuario 1">
                    </div>
                      <div>
                        <p><strong>{{comment.username}}</strong></p>
                      </div>
                      <div style="margin-top: -1rem;">
                        <p style="font-size: 0.7rem;">Experto</p>
                      </div>
                      <div style="margin-top: -1rem;">
                        <p style="font-size: 0.7rem;">{{comment.parsedDate}}</p>
                      </div>
                      <div style="margin-top: -1rem;">
                        <a class="" style="color: #e82c2c" [routerLink]="" (click)="deleteComment(comment.id)"><i class="fa fa-lg fa-trash"></i></a>
                      </div>
                  </div>
                  <div class="comment-content">
                      <p>{{comment.content}}</p>
                  </div>
              </div>
              <hr>
          </li>
          <!-- Puedes agregar más comentarios aquí -->
      </ul>
  </div>
  </div>
  

  <div class="my-panel row">
    <div class="col">
      <form id="comment-form">
          <div class="form-group">
              <textarea id="comment-text" [(ngModel)]="commentContent" name="comment-text" rows="4" required></textarea>
          </div>
          <div class="form-group">
              <button type="button" (click)="addComment()" class="btn btn-primary">Comentar</button>
          </div>
      </form>
    </div>
  </div>
</div>




</ng-container>
