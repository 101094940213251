import { Injectable } from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import { Observable } from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { onLoggedout } from '@modules/shared/shared.component';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@env/environment';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
    constructor(private router: Router, private toastr: ToastrService, private translocoService: TranslocoService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const userToken = localStorage.getItem('token');
        let request = req;
        if (userToken) {
            request = req.clone({
                headers: req.headers.set('token', userToken),
                withCredentials: environment.api.withCredentials
            });
        }

        return next.handle(request).pipe(tap(() => {},
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        onLoggedout();
                        this.router.navigate(['/login']);
                    } else if (err.status === 500) {
                      this.toastr.error(this.translocoService.translate('common.internal_error'));
                    } else {
                      return;
                    }
                }
            }));
    }
}
